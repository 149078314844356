import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import SwiperCore, { Navigation, Controller, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Integrationspage from "./integrationspage"
import Card from "./../components/card"
import "./../styles/swiper/swiper.min.css"
import "./../styles/swiper/pagination.min.css"
import "./../styles/vertical/vertical.scss"
import SEO from "../components/SEO"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import { FaStar } from 'react-icons/fa'
import { ArrowScrollToTop } from "../components/arrowScrollToTop"
import FAQ from "../components/FAQ"

// import Swiper core and required modules
SwiperCore.use([Navigation, Controller, Pagination])


const VerticalCircularEconomy = ({data,   
  pageContext: { locale, subPages },
  location,}) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [featureSwiperThumbs, setFeatureSwiperThumbs] = useState(null)
    const [featureSwiper, setFeatureSwiper] = useState(null)
    //parametre for swiper "slider-main"
    const [dotSelected, setDotSelected] = useState(null)
    const [swipeActivate, setSwipeActivate] = useState(true)
    const [firstSwiper, setFirstSwiper] = useState(null)
    const [secondSwiper, setSecondSwiper] = useState(null)
    const dataPage = data.prismic.vertical
    const SEOData = {
      meta_title: data.prismic.vertical.meta_title,
      meta_description: data.prismic.vertical.meta_description1,
    };
  
    // creations des tableaux pour les types slide ou group dans prismic
    //creation du tableau de context 
      const itemContext = []
      dataPage.context_slice[0].fields.map(item=>{
        itemContext.push(
          {
            content : item.vertical_context_detail[0].text
          }
        );
      })
  
  
  
    // //creation du tableau de pain
      const itemPains=[];
      dataPage.pains_slice[0].fields.map(item=>{
        itemPains.push({
          url: item.vertical_pain_image_description?.url,
          alt: item.vertical_pain_image_description?.alt,
          subtitle: item.vertical_subtitle_description[0]?.text,
          content : item.vertical_pains_content[0]?.text
        })
      })
     
    //   //creation du 1er  tableau de gain
      const itemGain=[];
      dataPage.vertical_gains_slice[0].fields.map(item=>{
        itemGain.push({
          url: item.vertical_icone_gain?.url,
          alt: item.vertical_icone_gain?.alt,
          title:item.vertical_gain_percent_description[0]?.text,
          subtitle:item.vertical_gain_percent_subtitle_description[0]?.text
        })
      })
  
      //tableau client 
      const itemClient=[];
      let tab=[];
      dataPage.vertical_clients[0].fields.map((item,i)=>{
            tab.push({
              url: item.vertical_client_logo?.url,
              alt: item.vertical_client_logo?.alt
            })
            if(i%3 === 2 || i === dataPage.vertical_clients[0]?.fields.length -1){
              itemClient.push(tab);
              tab = []
              
            }
      })
   // tableau integration
      const itemIntegration=[]
      dataPage.vertical_integration[0].fields.map(item=>{
        itemIntegration.push({
          url:item.vertical_integration_logo_client?.url,
          alt:item.vertical_integration_logo_client?.alt,
          leftTitle:item.vertical_integration_title_left[0]?.text,
          rightTitle:item.vertical_integration_title_right[0]?.text,
          logoTitle:item.vertical_integration_title_logo[0]?.text,
          logoSubtitle:item.vertical_integration_subtitle_logo[0]?.text
        })
      })
   
      //   //tableau carousel
  
      const itemCarouselIcon=[];
      const itemCarouselContent=[];
      dataPage.vertical_carousel[0].fields.map((item, i)=>{
        itemCarouselIcon.push({
          id: i,
          url1: item.carousel_icone?.url,
          alt1: item.carousel_icone?.alt,
        });
        itemCarouselContent.push({
          id: i,
          url2: item.carousel_ilmage?.url,
          alt2: item.carousel_ilmage?.alt,
          title: item.carousel_title[0]?.text,
          subtitle: item.carousel_subtitle && item.carousel_subtitle[0]?.text,
          button: item.carousel_video[0]?.text,
          linkUrl: item.carousel_video_link?.url
        })
      })
  
    //   // tableau pour key point
      const itemKey_points=[]
      dataPage.vertical_key_points[0].fields.map(item=>{
        itemKey_points.push({
          content:item.vertical_description_key_points[0]?.text
        })
      })
  
    //   //tableau pour les avis
      const itemAvis=[]
      dataPage.vertical_avis[0].fields.map(item=>{
        itemAvis.push({
          url:item.vertical_logo_client_avis && item.vertical_logo_client_avis.url,
          alt:item.vertical_logo_client_avis && item.vertical_logo_client_avis.alt,
          title:item.vertical_title_description_avis && item.vertical_title_description_avis[0]?.text,
          content:item.vertical_content_description_avis && item.vertical_content_description_avis[0]?.text ,
          author:item.vertical_avis_autor && item.vertical_avis_autor[0]?.text 
        })
      })
  
    //   //tableau pour les blog
      const itemBlog=[]
      dataPage.vertical_blog[0].fields.forEach(item=>{
        itemBlog.push({
          node: item.blog_title_link,
        })
      })

    /* const faqRawData = data.prismic.allFaqs.edges[0].node

    const faqData = []

    faqRawData.faq_fields.map(faq => {
      const data = {
        question: faq.faq_fields_question[0].text,
        answer: faq.faq_fields_answer[0].text
      }

      faqData.push(data)
    }) */
      
  
    function previousClick(){
      if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
      else setCurrentIndex(itemCarouselIcon.length -1)
    
    }
    
    function nextClick(){
      if (currentIndex < itemCarouselIcon.length -1) setCurrentIndex(currentIndex + 1)
      else setCurrentIndex(0)
      
    }
    
    return(
      
      <>
        <SEO
          title={SEOData.meta_title}
          desc={SEOData.meta_description}
          metadata={data.site.siteMetadata}
          locale={locale}
          location={location}
          pathname={location.pathname}
        /> 

      {/* ------------------SECTION1-------------------------------- */}
      <div className="container-fluid" css={verticalCss}>
            <section className="section is-themeGradientH is-theme2 section-context">
              <div className="container">
                <div className="columns ml-1">
                  <div className="column">
                  <h1 className="mainTitle is-size-3 titre">{dataPage.vertical_title1[0].text}</h1>
                  <p className="mainSubtitle is-size-6 has-text-weight-light content">{dataPage.vertical_title2[0].text}</p>
                  <a href={dataPage.vertical_video_link.url} target="_blank" className="button is-rounded button__primary has-text-white mr-3 mb-3">{dataPage.vertical_video[0].text}</a>
                  <a href={dataPage.vertical_free_link.url} target="_blank" id="trial-bt" className="button is-rounded is-outlined is-link">{dataPage.vertical_free[0].text}</a>
                  </div>
                  <div className="column has-text-centered">
                      <img 
                      src={dataPage.vertical_usecase_image.url} 
                      alt={dataPage.vertical_usecase_image.alt} 
                      />
                  </div>
                  </div>
              </div>
            </section>
        

        {/* ------------------SECTION CONTEXTE-------------------------------- */}
            <section className="section-context ">
              <div className="container retail-context">
                <div className="columns container-bloc">
                  <div className="column container1">
                      <div className="columns titre">
                          <p>{dataPage.context_slice[0].primary.vertical_context_title[0].text}</p>
                      </div>
                        <div className="columns content">
                          <p>{dataPage.context_slice[0].primary.vertical_title[0].text}</p>
                        </div>
                        <div className="columns content1">
                          <p>
                             {/* ajouter le texte de la derniere zone de texte implémenté dans prismic */}
                            {dataPage.context_slice[0].primary.context_content && dataPage.context_slice[0].primary.context_content[0].text}
                          </p>
                        </div>
                  </div>
                  <div className="column container2">
                      {itemContext.map((item, index) =>{
                        return(
                          <div key={index} className="mainSubtitle is-size-6 has-text-weight-light content"><FaStar/> <b>{item.content}</b></div>
                        )
                        
                        })}
                  </div>
                </div>
              </div>
            </section>
          

          {/* ------------------SECTION PAINS------------------------------- */}

            <section className="section-pains">
              <div className="container">
                <div className="columns container-bloc">
                  <div className="column container1">
                      <p className="titre">{dataPage.pains_slice[0].primary.vertical_title_pains[0].text}</p>
                      {dataPage.pains_slice[0].primary.vertical_subtitle_pain &&
                        <p className="mainSubtitle is-size-6 has-text-weight-light sous-titre">{dataPage.pains_slice[0].primary.vertical_subtitle_pain[0].text}</p>
                      }
                      {itemPains.map((item, index)=>{
                        return(
                          <>
                          <div key={index} className="columns is-gapless content block">
                            <div className="column is-one-fifth">
                                  <div className="content-1 petit-container1">
                                  <img 
                                src ={item.url}
                                alt={item.alt}
                                />
                                  </div>
                            </div>
                            <div className="column petit-container2">
                                  <div className="content-2 chiffre">
                                  <h4>{item.subtitle}</h4>
                                  </div>
                                  <div className="content-3 content">
                                  <p>{item.content}</p>
                                </div>
                            </div>
                          </div>
                          </>
                        )
                      })}
                  </div>
                  <div className="column container2">
                      <img 
                        src ={dataPage.pains_slice[0].primary.vertical_image_pain.url}
                        alt={dataPage.pains_slice[0].primary.vertical_image_pain.alt}
                        className="img-pain"
                        />
                  </div>
                </div>
              </div>
            </section>
            

          {/* ------------------SECTION GAINS------------------------------- */}

            <section className="section-gain">
            <div className="container">
                <div className="columns container-bloc">
                  <div className="column gain-container1">
                      <p className="mainTitle is-size-3 titre">{dataPage.verticale_gains[0].vertical_gains_title[0].text}</p>
                      <p className="mainSubtitle is-size-6 has-text-weight-light sous-titre">{dataPage.verticale_gains[0].vertical_gains_content[0].text}</p>
                      <a href={dataPage.verticale_gains[0].vertical_gains_video_link.url} target="_blank" id="trial-bt" className="button is-rounded button__primary has-text-white mr-3 mb-3">{dataPage.verticale_gains[0].vertical_gains_video[0].text}</a>
                  </div>
                  <div className="column container2">
                  <img src={dataPage.vertical_gains_image.url} alt={dataPage.vertical_gains_image.alt} />
                    <div className="container_gain">
                      {itemGain.map((item,i)=>{
                        // Temporaire vu qu'il y a 7 élément au lieu de 6 au moment de la modification
                        if(i === 6) {
                          return null
                        }
                        return(
                          <div key={i} className={`item${i}`}>
                            <img src ={item.url} alt={item.alt} />
                            <div className="content_gain">
                              <h4 className="title_gain">{item.title}</h4>
                              <span className="subtitle_gain">{item.subtitle}</span>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  </div>
                </div>
              </div>
              </section>
{/* ------------------SECTION CLIENT------------------------------- */}

        <section className="section clients__section">
          <div className="container">
            <h3 className="title is-3 has-text-centered">
              {dataPage.vertical_client_title && dataPage.vertical_client_title[0].text}
            </h3>
            <div className="columns is-centered">
              <div className="column is-12">
              <Swiper 
              loop
              navigation={{
                nextEl: ".swiper-case-button-next",
                prevEl: ".swiper-case-button-prev",
              }}
              className="swiperClass"
                >
                  {dataPage.vertical_clients.map((slideClient, i) => {
                    return (
                      <SwiperSlide key={`clientSlide-${i}`}>
                        <div className="columns is-multiline is-centered is-mobile py-6">
                          {slideClient.fields.map((clientLogo, y) => {
                            
                            return(
                            <div
                              key={`clientLogo-${y}`}
                              className="column is-2 clients"
                            >
                              <a href={clientLogo.client_website?.url}
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                              >
                                <img
                                  src={
                                    clientLogo.vertical_client_logo?.url
                                  }
                                  alt={
                                    clientLogo.vertical_client_logo?.alt
                                  }
                                />
                              </a>
                            </div>
                          )})}
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                <div
                role="button"
                name="Previous Slide"
                className="swiper-button-prev swiper-case-button-prev"
              ></div>
              <div
                role="button"
                name="Next Slide"
                className="swiper-button-next swiper-case-button-next"
              ></div>

              </div>
            </div>
          </div>
        </section>
        {/* ------------------SECTION SCHEMA------------------------------- */}
            <section className="section-schema">
              <div className="container">
                  <div className="columns is-centered">
                    <h3 className="title is-3 has-text-centered">{dataPage.vertical_section_title && dataPage.vertical_section_title[0].text}</h3>
                  </div>
                  <div className="columns">
                    <img src={dataPage.vertical_simple_image && dataPage.vertical_simple_image.url} alt={dataPage.vertical_simple_image && dataPage.vertical_simple_image.alt}/>
                  </div>
                </div>
            </section>


{/* -------------------------------SECTION CAROUSEL------------------------------- */}

            <section className="section-carousel">
              <div className="container vertical-container">
                    <div className="columns swipper-logo">
                    <Swiper
                      centeredSlides={true}
                      loop={true}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                      }}
                      loopedSlides={1}
                      onSwiper={setFeatureSwiperThumbs}
                      slideToClickedSlide={true}
                      controller={{ control: featureSwiper }}
                      navigation={{
                        nextEl: ".swiper-features-button-next",
                        prevEl: ".swiper-features-button-prev",
                      }}
                      lassName="swiperClass"
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      className="swiper-features"
                      onSlideChange={() => setTimeout}
                      onSlideChangeTransitionStart={() => {setDotSelected(false);setSwipeActivate(true)}}
                      //gestion de l'affichage de la slide suivante
                      allowSlideNext={swipeActivate}
                      //distance minimal en pixel pour que le slider prenne en compte l'action
                      threshold={30}
                    >
                  {itemCarouselIcon.map((carousel,i)=>{
                    return(
                      <SwiperSlide key={i}>
                        <div className="column is-centered logo" >
                          <div className="columns">
                          <img src={carousel.url1} alt={carousel.alt1} className="icone-carousel"/>
                          </div>
                          <span className="title-logo">{carousel.alt1}</span>
                        </div>
                      </SwiperSlide>
                    )}
                    )}
                  </Swiper>
                  <div
                role="button"
                name="Previous Slide"
                aria-label="Previous slide"
                className="swiper-button-prev swiper-features-button-prev prev prev-btn"
              ></div>
              <div
                role="button"
                name="Next Slide"
                aria-label="Next Slide"
                className="swiper-button-next swiper-features-button-next next next-btn"
              ></div>

                </div> 
                  {/* Swipper itemCarouselContent */}
                  <div className="columns">
                  <Swiper
                    onSwiper={setFeatureSwiper}
                    controller={{ control: featureSwiperThumbs }}
                    loop={true}
                    loopedSlides={1}
                    //gestion de l'affichage de la slide précédente et suivante
                    allowSlidePrev={swipeActivate}
                    allowSlideNext={swipeActivate}
                    //distance minimal en pixel pour que le slider prenne en compte l'action
                    threshold={30}
                  >
                  {itemCarouselContent.map((carousel,i)=>{
                    return(
                      <SwiperSlide key={i}>
                        <div className="columns" >
                          <div className="column is-three-fifths" >
                            <img src={carousel.url2} alt={carousel.alt2} className="img-carousel"/>
                          </div>
                          <div className="column fils" >
                            <p className="first-paragraphe">
                              {carousel.title}
                              </p>
                            <p className="second-paragraphe">{carousel.subtitle}</p>
                            <a href={carousel.linkUrl} target="_blank" id="trial-bt" className="button is-rounded button__primary has-text-white mr-3 mb-3 carousel-btn">{carousel.button}</a>
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                  )}
                  </Swiper>
                  </div>
              </div>
            </section>


        {/* <FAQ data={faqData} title={faqRawData.faq_title[0].text} /> */}

{/* ------------------------SECTION POINT CLES----------------------------- */}



            <section className="section-points-cles">
            <div className="container">
                <div className="columns container-bloc">
                  <div className="column container1">
                      <div className="columns">
                          <h3 className="titre">{dataPage.vertical_key_points[0].primary.vertical_title_key_points[0].text}</h3>
                      </div>
                        <div className="columns">
                          <p className="first-paragraphe">{dataPage.vertical_key_points[0].primary.vertical_content_key_points[0].text}</p>
                        </div>
                        <div className="columns content1">
                          <p>
                            {/* ajouter le texte de la derniere zone de texte implémenté dans prismic */}
                            {dataPage.vertical_key_points[0].primary.key_point_content && dataPage.vertical_key_points[0].primary.key_point_content[0].text}
                          </p>
                        </div>
                        <div className="columns">
                        <a href={dataPage.vertical_key_points[0].primary.vertical_key_points_video_link.url} target="_blank" id="trial-bt" className="button is-rounded button__primary has-text-white mr-3 mb-3 point-cle-btn">{dataPage.vertical_key_points[0].primary.vertical_key_point_video[0].text}</a>
                        </div>
                        
                  </div>
                  <div className="column container2 ">
                      {itemKey_points.map((item, index) =>{
                        return(
                          <div key={index} className="mainSubtitle is-size-6 has-text-weight-light content"><FaStar/> <b>{item.content}</b></div>
                        )
                      })}
                  </div>
                </div>
                  
              </div>
            </section>

        {/* ------------------SECTION BLOG-------------------------- */}
        
            <section className="section blog__related-posts">
              <div className="container">
                <div className="columns is-centered">
                    <h3 className="title is-3 has-text-centered">{dataPage.vertical_blog[0].primary.blog_title[0].text}</h3>
                </div>
                
                <div className="columns is-multiline">
                    {itemBlog.map((item, index)=>{
                      return(
                        <div key={index} className="column is-3 blog__thumbnail">
                            <Card postData={item}/>
                        </div>
                      )
                    })}
                </div>
                <div>
                </div>
              </div>
            </section>
      </div>
      <ArrowScrollToTop />
      </>
    )
}

VerticalCircularEconomy.propTypes = {
    data: PropTypes.shape({
      prismic: PropTypes.object.isRequired,
    }).isRequired,
  }


  
  export const verticalindustryQuery = graphql`
  query verticalCircularEconomyQuery($locale: String!) {
    prismic {
      integrationspage: integrationspage(uid: "integrations", lang: $locale) {
        _meta {
          lang
          uid
        }
        meta_title
        meta_description
        title
        subtitle
        title_search
        title_categories
        all
        sort
        popular
        featured
        newest
        request_integration_button
        main_image
        body1 {
          ... on PRISMIC_IntegrationspageBody1Form {
            primary {
              form_id
              form_title
              form_button
              form_success_message
            }
            fields {
              form_field_id
              form_field_name
              form_field_type
              form_field_placeholder
              form_field_list
              field_is_required
            }
          }
        }
      }
      allIntegrations: allIntegrations(
        lang: $locale
        sortBy: meta_firstPublicationDate_DESC
      ) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
            is_featured
            is_popular
            main_image
          }
        }
      }
      ...Trial
      allBlogposts {
        edges {
          node {
            post_date
          }
        }
      }
      vertical(uid: "vertical-circular-economy", lang: $locale) {
        vertical_title1
        vertical_title2
        vertical_usecase_image
        vertical_video
        vertical_video_link {
          ... on PRISMIC__ExternalLink {
            url
            target
          }
        }
        vertical_free
        vertical_free_link {
          ... on PRISMIC__ExternalLink {
            url
            target
          }
        }
        vertical_back
        vertical_back_link {
          ... on PRISMIC__ExternalLink {
            url
            target
          }
        }
        context_slice {
          ... on PRISMIC_VerticalContext_sliceVertical_context_slice {
            primary {
              vertical_context_title
              vertical_title
              context_content
            }
            fields {
              vertical_context_detail
            }
          }
        }
        pains_slice {
          ... on PRISMIC_VerticalPains_sliceVertical_pains {
            primary {
              vertical_title_pains
              vertical_subtitle_pain
              vertical_image_pain
            }
            fields {
              vertical_pain_image_description
              vertical_subtitle_description
              vertical_pains_content
            }
          }
        }
        verticale_gains {
          vertical_gains_title
          vertical_gains_content
          vertical_gains_video
          vertical_gains_video_link {
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
        }
        vertical_gains_image
        vertical_gains_slice {
          ... on PRISMIC_VerticalVertical_gains_sliceVertical_gain_percent {
            fields {
              vertical_icone_gain
              vertical_gain_percent_description
              vertical_gain_percent_subtitle_description
            }
          }
        }
        vertical_clients {
          ... on PRISMIC_VerticalVertical_clientsVertical_logo_client_slice {
            fields {
              vertical_client_logo
            }
          }
        }
        vertical_client_title
        vertical_section_title
        vertical_simple_image
        vertical_carousel {
          ... on PRISMIC_VerticalVertical_carouselVertical_galery_slice {
            primary {
              carousel_title
              carousel_subtitle
            }
            fields {
              carousel_icone
              carousel_ilmage
              carousel_title
              carousel_subtitle
              carousel_video
              carousel_video_link {
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
              }
            }
          }
        }
        vertical_integration {
          ... on PRISMIC_VerticalVertical_integrationVertical_integration_slice {
            primary {
              vertical_integration_title
            }
            fields {
              vertical_integration_logo_client
              vertical_integration_title_left
              vertical_integration_title_right
              vertical_integration_title_logo
              vertical_integration_subtitle_logo
            }
          }
        }
        vertical_key_points {
          ... on PRISMIC_VerticalVertical_key_pointsPoint_cles {
            primary {
              vertical_title_key_points
              vertical_content_key_points
              key_point_content
              vertical_key_point_video
              vertical_key_points_video_link {
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
              }
            }
            fields {
              vertical_description_key_points
            }
          }
        }
        vertical_avis {
          ... on PRISMIC_VerticalVertical_avisAvis_des_clients {
            primary {
              vertical_avis_title
              vertical_avis_subtitle
            }
            fields {
              vertical_logo_client_avis
              vertical_title_description_avis
              vertical_content_description_avis
              vertical_avis_autor
            }
          }
        }
        vertical_blog {
          ... on PRISMIC_VerticalVertical_blogBlog_slice {
            primary {
              blog_title
            }
            fields {
              blog_title_link {
                ... on PRISMIC__Document {
                  _meta {
                    lang
                    uid
                    firstPublicationDate
                  }
                  ... on PRISMIC_Blogpost{
                    post_date
                    title
                    main_image
                    
                  }
                }
              }
            }
          }
        }
        meta_title
    	  meta_description1
      }
    }
    site {
      ...SiteInformation
    }
  }
`
export default VerticalCircularEconomy;
const verticalCss = css `
.section-gain .content{
  position: absolute;
  top:555px;
  width:20%;
  left:75%;
}
.section-gain .item0,.item1,.item2,.item3,.item4,.item5,.item6{
  display:flex;
  justify-content:center;
  align-items:center;
  padding:20px;
  column-gap: 10%;
}
.section-gain .container2{
  position: relative;
}
.container_gain {
  position: absolute;
  left: 47%;
  top: 1.3%;
  display: grid; 
  grid-template-columns: 8.7% 31.78% 8.7%; 
  grid-template-rows: repeat(6, 13.8%); 
  gap: 2.5% 0px; 
  grid-template-areas: 
    ". item0 item0"
    "item1 item1 ."
    ". item2 item2"
    "item3 item3 ."
    ". item4 item4"
    "item5 item5 ."
    ". item6 item6";
  width: 100%; 
  height: 100%; 
}
.item0 { grid-area: item0; }
.item1 { grid-area: item1; }
.item2 { grid-area: item2; }
.item3 { grid-area: item3; }
.item4 { grid-area: item4; }
.item5 { grid-area: item5; }
.item6 { grid-area: item6; }

.container_gain .content_gain{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.container_gain img{
  width: 35%;
}

.container_gain .content_gain .subtitle_gain{
  font-size: 88%;
  font-weight: normal;
}

.container_gain .content_gain .title_gain{
  font-size: 150%;
  font-weight: 500;
}

@media only screen and (max-width: 1392px){
  .container_gain img{
    width: 50px;
  }
  
  .container_gain .content_gain .subtitle_gain{
    font-size: 14px;
  }
  
  .container_gain .content_gain .title_gain{
    font-size: 22px;
  }
}
@media only screen and (max-width: 1216px){
  .container_gain img{
    width: 43px;
  }
  
  .container_gain .content_gain .subtitle_gain{
    font-size: 11px;
  }
  
  .container_gain .content_gain .title_gain{
    font-size: 20px;
  }
}
@media only screen and (max-width: 898px){
  .container_gain img{
    width: 43px;
  }
  .container_gain .content_gain .subtitle_gain{
    font-size: 9px;
  }
  .container_gain .content_gain .title_gain{
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px){
  .container_gain img{
    width: 80px;
  } 
  .container_gain .content_gain .subtitle_gain{
    font-size: 13px;
  }
  .container_gain .content_gain .title_gain{
    font-size: 28px;
  }
}
@media only screen and (max-width: 560px){
  .container_gain img{
    width: 55px;
  } 
  .container_gain .content_gain .subtitle_gain{
    font-size: 13px;
  }
  .container_gain .content_gain .title_gain{
    font-size: 21px;
  }
}
@media only screen and (max-width: 465px){
  .container_gain img{
    width: 50px;
  } 
  .container_gain .content_gain .subtitle_gain{
    font-size: 10px;
  }
  .container_gain .content_gain .title_gain{
    font-size: 21px;
  }
}
@media only screen and (max-width: 376px){
  .container_gain img{
    width: 35px;
  } 
  .container_gain .content_gain .subtitle_gain{
    font-size: 10px;
  }
  .container_gain .content_gain .title_gain{
    font-size: 14px;
  }
}
.clients__section {
  .gatsby-image-wrapper {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-height: 100px !important;
  }
  .clients {
    min-width: 250px;
  }
  background: #ffffff;
}

.clients__section {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: transparent;
  }
  .swiper-slide .columns {
    display: flex;
    gap: 50px;
  }
}

.clients__section .column.is-2.clients{
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-theme2 {
  background: #f0f5ff;
  margin-top: -1%
}

.section-context{
  background-color: #EFF3FE;
}
.section-context .container-bloc{
    display: flex;
    align-items: flex-start;
    column-gap: 25vh;
}
.section-context .container1{
  width:42.69%;
  display:flex;
  flex-direction:column;
  row-gap:10px;
}
.section-context .container2{
  width:42.69%;
}
.section-context .container1 .titre{
  color: #FF583D;
  font-size:26px !important;
  margin-top:0 ;
  }
  .section-context .container1 .content{
    
    }
    .section-context .content1 {
      word-wrap: break-word; 
    }
    .section-context .content1 p{
      width:inherit; 
      word-break: break-all;
    }  
  .section-context .container2 .content{
  margin :20px;
  border-radius: 10px;
  padding : 10px;
  background-color: #F0F5FF;
  color: #3364c6;
  }
  .section-context .container2 .content:hover{
    background-color: #F0F5FF;
    color: #3364c6;
  }

  .section-points-cles{
    background-color: #EFF3FE;
    margin-top: 50px;
    padding: 50px 0 0 0;
  }
  .section-points-cles .container-bloc{
      display: flex;
      align-items: flex-start;
      column-gap: 25vh;
  }
  .section-points-cles .container1{
    width:42.69%;
    display:flex;
    flex-direction:column;
    row-gap:10px;
  }
  .section-points-cles .container2{
    width:42.69%;
  }
  
  .section-points-cles .container1 .titre{
    color: #FF583D;
    font-size:26px !important;
    margin-top:0 ;
    }

    .section-points-cles .container1 .content{
      font-size: 26px;
      }
      .section-points-cles .content1 {
        word-wrap: break-word; 
      }
      .section-points-cles .content1 p{
        width:inherit; 
        word-break: break-all;
      }  
      .section-points-cles .first-paragraphe{
        font-size:24px;
        margin-bottom: 1.5rem;
      }
    .section-points-cles .container2 .content{
    margin :20px;
    border-radius: 10px;
    padding : 10px;
    background-color: #F0F5FF;
    color: #3364c6;
    }
    .section-points-cles .container2 .content:hover{
      background-color: #F0F5FF;
      color: #3364c6;
    }

@media only screen and (min-width: 769px) and (max-width: 1030px){
  .section-context .container-bloc{
    padding-left:5%;
    column-gap:100px;
  }
  .section-points-cles .container-bloc{
    padding-left:5%;
    column-gap:100px;
  }

  .section-pains .container-bloc{
    padding-left: 5%;
  }
}






.benefits__button {
  justify-content: left;
  border: 0;
  background-color: #c1c7e7;
  color: #3364c6;

  span {
    color: ${colors.blue};
    opacity: 34%;
    font-size: 25px;
    padding-left: 30px;
    padding-right: 50px;
    height: auto;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.active,
  &:hover {
    box-shadow: 0 12px 15px 0 rgba(61, 101, 191, 0.31);
    background-color: ${colors.blue};
    color: #fff;

    span {
      color: #f7b500;
      opacity: 1;
    }
  }
}
@media(max-width: 499px) {
  .benefits__button {
    height: auto;
  }
}

  .gain-container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;

 
}
@media(max-width: 768px) {
  .section-context .container-bloc{
    flex-direction: column;
    align-item: center;
    justify-content:center;
  }
  .section-context .container2{
    width: 90% !important;
  }
  .section-context .container1{
    width:92% !important;
    padding-left: 10vw;
    padding-right: 10vw;
  }


  .section-points-cles .container-bloc{
    flex-direction: column;
    align-item: center;
    justify-content:center;
    
  }
  .section-points-cles .container2{
    width: 90% !important;
  }
  .section-points-cles .container1{
    width:92% !important;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .section-pains{
      width:100vw;
      margin:10px;
  }
  .section-pains .container-bloc{
    flex-direction: column;
    width:100%;
  }
  .section-pains .block{
    width: 100% !important; 
  }
  .section-pains .titre{
    width:inherit;
    word-break: break-all;
  }
  .section-pains .img-pain{
    margin-top:5px !important;
  }


  .section-gain .container-bloc{
    flex-direction: column;
    align-item: center;
    justify-content:center;
  }
}

.section-pains{
 margin-top: -8rem;
}
.benefits__button {
  font-size: .85em;
  white-space: inherit;
  text-align: left;
}
.benefits__button {
  span {
    height: 0;
  }
}



.section-pains .container-bloc{
  margin-top: 10rem;
display:flex;
align-items: center;
z-index:0;
margin-gap:200px;
}
.section-pains .container2{
width:50%;
}
.section-pains .img-pain{
width:100%;
margin-top: 100px;
}
.section-pains .block{
  display:flex;
  align-items:center;
  background: white;
  border-radius:10px;
  margin-top:20px;
  border: solid white;
  z-index:1000;
  padding:10px;
  width: 550px;
  column-gap: 20px;
  box-shadow:5px 5px 10px gray ;
  max-width: 70%;
  }
.secton-pains .content-1{
  display:inline;
} 
.section-pains .titre{
  color:#fd7543;
  font-size:26px;
  } 
.section-pains .sous-titre{
  margin-bottom: 1.5rem;
  font-size:26px !important;
}
.section-pains h4{
  font-size: 28px ;
  margin-bottom: 5% ;
}
.section-pains h4{
  margin-bottom: 0;
  margin-top: 0;
}
.section-pains .content{
  font-size: 14px;
}
.section-pains .petit-container1{
  margin-left:20px;
}
.section-gain{
  margin-top: 5rem;
  background:#EEF3FF;
}
.section-gain .titre,.sous-titre{
  margin-bottom: 1.5rem;
} 

.section-schema{
  margin-top: 5rem;
}

.section-carousel .icone-carousel{
  width:80%;
  margin-top:25px;
}
.section-carousel .logo{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
  img {
    max-height: 74px;
  }
}

.section-carousel .logo:hover{
  cursor:pointer;
  color:#fd7543;
  column-gap: 20px;
  
  border-radius: 10px;
}

.swipper-logo {
  margin-bottom: calc(1.5rem - 0.75rem)
}

.section-carousel .swipper-logo{
margin-left:5%;
margin-right:5%;
display:flex;
justify-content:center;
} 

.carousel-btn{
  margin-top: 1rem;
  margin-left:23px;
}
.section-carousel .next-btn,.prev-btn{
  top: 69px;
}

.section-carousel p{
  margin: 10px;
  padding:15px;
}
.section-carousel .first-paragraphe{
  color:  #5769a4;
  font-weight: 600;
}

.section-carousel .second-paragraphe{
  color:  #5769a4;
}

.section-carousel .img-carousel{
  widht:900px;
}



.card {
  box-shadow: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-content {
  padding: 1.5rem 0;
}
.card-image {
  border-radius: 10px;
  
}

`
const feedBackVCss = css`
  .box {
    border-radius: 12px;
    box-shadow: 0 19px 7px 0 #c5ccde;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  .dash-spaced {
    background-image: linear-gradient(
      to right,
      ${colors.lightgray} 30%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    width: 200px;
    margin: 0 auto;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    .gatsby-image-wrapper {
      height: 60px;
      width: 200px;
    }
  }

  .swiper-feedback {
    padding-top: 40px;
    padding-bottom: 40px;

    .speech-bubble-text {
      color: ${colors.blueLightSeven};
    }
  }

  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
    }
  }

  @media (min-width: 1024px) {
    .swiper-feedback .swiper-slide.swiper-slide-next {
      overflow: visible;
      right: 33%;
      opacity: 30%;
    }

    .swiper-feedback .swiper-slide.swiper-slide-prev {
      overflow: visible;
      left: 33%;
      opacity: 30%;
    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      left: 12%;
      right: auto;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      right: 12%;
      left: auto;
    }

  }
`